import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'
import { $themeConfig } from '@themeConfig'

export default function tableSatisfactionTransactionList() {
  // Use toast
  const toast = useToast()

  const refSatisfactionTransactionListTable = ref(null)
  const lineOa = computed(() => store.state.lineapi.lineOa)

  const msgList = {
    errorfetch: t2('Error fetching {module} list', { module: t('FormSatisfactionTransaction.SatisfactionTransaction') }),
    id: `${t('ID')} » ${t('FormSatisfaction.Name')} » ${t('FormSatisfaction.Action')} » ${t('FormSatisfaction.Date')}`,
    form: `${t('FormSatisfaction.Form')}`,
    satisfaction_transaction: `${t('FormSatisfactionTransaction.SatisfactionTransaction')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, label: msgList.id },
    { key: 'form', sortable: true, label: msgList.form },
    { key: 'satisfaction_transaction', sortable: true, label: msgList.satisfaction_transaction },
    { key: 'actions', label: msgList.actions },
  ]

  const perPage = ref(10)
  const totalSatisfactionTransactions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const locale = ref('th')
  const nameFilter = ref('')
  const actionFilter = ref('')
  const isResultFilter = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refSatisfactionTransactionListTable.value ? refSatisfactionTransactionListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSatisfactionTransactions.value,
    }
  })

  const refetchData = () => {
    refSatisfactionTransactionListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, locale, nameFilter, actionFilter, isResultFilter], () => {
    refetchData()
  })

  const getListSatisfactionTransaction = (ctx, callback) => {
    const { id } = JSON.parse(lineOa.value)
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
      name: nameFilter.value,
      action: actionFilter.value,
      isResult: isResultFilter.value,
      lineOaId: id,
    }
    store
      .dispatch('store-satisfaction-transaction/getListSatisfactionTransaction', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalSatisfactionTransactions.value = total
        localStorage.setItem(`${$themeConfig.app.session}-filter-satisfaction-transaction-list`, JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const isResultOptions = [
    {
      label: t('All'),
      value: '',
    },
    {
      label: t('Result'),
      value: 1,
    },
    {
      label: t('Cancel'),
      value: 2,
    },
  ]

  return {
    getListSatisfactionTransaction,
    tableColumns,
    perPage,
    currentPage,
    totalSatisfactionTransactions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refSatisfactionTransactionListTable,

    refetchData,

    nameFilter,
    actionFilter,
    isResultFilter,

    isResultOptions,
  }
}
