import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultSatisfactionTransaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_default_satisfaction_transaction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListSatisfactionTransaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_satisfaction_transaction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSatisfactionTransaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_satisfaction_transaction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSatisfactionTransaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_satisfaction_transaction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
